<template>
  <button
    class="btn btn-sm"
    :title="$t(`receipt.downloadInvoice`)"
    v-on:click="show"
    v-if="value?.number"
  >
    <span class="regular-12 text-black">
      {{value?.number}}
    </span>
  </button>
  <ReceiptModal ref="modal" v-model="value" />

</template>
<script>
import ReceiptModal from "../../modals/ReceiptModal";

export default {
  name: "Receipt",
  components: {ReceiptModal },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    show() {
      this.$refs.modal.showModal();
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
